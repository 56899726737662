import { green } from "@material-ui/core/colors";
import Loader from "react-js-loader";

function Spinner({ typeSpinner }) {
   if (typeSpinner) {
      return (
         <div className={"item"}>
            <Loader type={typeSpinner} bgColor="green" color="green" size={50} />
         </div>
      );
   }

   return (
      <div className={"item"}>
         <Loader type="spinner-circle" bgColor="green" color="green" size={50} />
      </div>
   );
}

export default Spinner;