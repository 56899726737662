import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EmailCorporate, PhoneCorporate, WhatsappCorporate } from '../../assets/data/company';

class Footer
    extends Component {
    render() {
        return (
            <>
                <footer className="site-footer text-uppercase">
                    <div className="footer-top bg-primary">
                        <div className="container" >
                            <div className="row">
                                <div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">Company</h5>
                                        <ul>
                                            <li><Link to={"/"}>Home</Link></li>
                                            <li><Link to={"/about-us"}>About Us</Link></li>
                                            <li><Link to={"/product"}>Product</Link></li>
                                            <li><Link to={"https://blog.arstnesia.com'"}>Blog</Link></li>
                                            <li><Link to={"/faqs"}>Faq's</Link></li>
                                            {/* <li><Link to={"#"}>About Us</Link></li> */}
                                            {/* <li><Link to={"#"}>Our Services</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">Useful Link</h5>
                                        <ul>
                                            <li><Link to={"#"}>Create Account</Link></li>
                                            <li><Link to={"#"}>Company Philosophy </Link></li>
                                            <li><Link to={"#"}>Corporate Culture</Link></li>
                                            <li><Link to={"#"}>Portfolio</Link></li>
                                            <li><Link to={"#"}>Project Details</Link></li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_getintuch">
                                        <h5 className="m-b30 text-white ">Contact us</h5>
                                        <ul>
                                            <li><i className="ti-location-pin"></i><strong>Head Office</strong> Tangerang, Banten, Indonesia </li>
                                            <li><i className="ti-mobile"></i><strong>phone</strong>
                                                <a href={`https://wa.me/${WhatsappCorporate}`} className="text-light-1" > {PhoneCorporate} </a>
                                                (24/7 Support Line)
                                            </li>
                                            <li className='text-lowercase'><i className="ti-email"></i><strong>email</strong>
                                                <a
                                                    href={`mailto:${EmailCorporate}`}
                                                    className="text-light-1"
                                                >
                                                    {EmailCorporate}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-8 col-sm-12 footer-col-12 ">
                                    <div className="widget">
                                        <h5 className="m-b30 text-white">About Company</h5>
                                        {/* <p className="text-capitalize m-b20">If you have any questions, you can contact with us so that we can give you a satisfying answer. Subscribe to our newsletter to get our latest products.</p> */}
                                        {/* <div className="subscribe-form m-b20">
                                            <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                                                <div className="dzSubscribeMsg"></div>
                                                <div className="input-group">
                                                    <input name="dzEmail" required="required" className="form-control" placeholder="Your Email Id" type="email" />
                                                    <span className="input-group-btn">
                                                        <button name="submit" value="Submit" type="submit" className="site-button">Subscribe</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div> */}
                                        {/* <div> */}
                                        {/* <img src="https://res.cloudinary.com/dyfgtumyj/image/upload/f_auto,q_auto/v1/assets/logo/arstnesia-logo-white" alt="arstnesia logo" className="m-b20" /> */}
                                        <p className='m-b20'>
                                            Arstnesia is a company that provides a variety of services for international trade. We are committed to providing the best service for our customers.
                                            We are a company that is always ready to help you in the field of international trade.
                                        </p>
                                        {/* </div> */}
                                        <ul className="list-inline m-a0">
                                            {/* <li><Link to={"#"} className="site-button facebook circle mr-1"><i className="fa fa-facebook"></i></Link></li>
                                            <li><Link to={"#"} className="site-button google-plus circle mr-1"><i className="fa fa-google-plus"></i></Link></li> */}
                                            <li><a href="https://www.instagram.com/arstnesia" className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></a></li>
                                            <li><a href="https://www.facebook.com/profile.php?id=100086918917155" className="site-button facebook circle mr-1"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="https://linkedin.com/company/arstnesia" className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></a></li>
                                            {/* <li><Link to={"#"} className="site-button twitter circle mr-1"><i className="fa fa-twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-left"> <span>Copyright © {new Date().getFullYear()} Arstnesia</span> </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                    <div className="widget-link ">
                                        <ul>
                                            <li><Link to={"#"}> Help Desk</Link></li>
                                            <li className="ml-1"><Link to={"#"}> Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer
    ;