import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, } from 'react-bootstrap';

const defaultAccordion = [
	{
		title: 'How do you ensure the quality & sustain of your products?',
		text:
			'We have strict quality control processes in place to ensure that all products meet international standards. We work closely with local farmers, fishermen, and artisans who use environmentally sustainable practices, ensuring that our products are ethically sourced and support the preservation of Indonesia’s natural resources.',
	},
	{
		title: 'How do your products contribute to local communities?',
		text:
			'Our business model is built on fair trade and ethical practices. By partnering with small-scale enterprises, we help provide economic opportunities for local farmers, artisans, and fishermen, while preserving their traditional crafts and environmentally friendly methods. This way, we create a positive social and economic impact in the communities where we operate.',
	},
	{
		title: `Can the product be customized?`,
		text:
			'Yes, we provide customization services according to customer preferences, Please contact us to discuss your specific requirements.',
	},
	{
		title: 'What payment methods do you accept?',
		text:
			'We accept two primary payment methods for international transactions: T/T (Telegraphic Transfer) and L/C (Letter of Credit) Irrevocable. These secure payment options ensure smooth and reliable transactions for our global partners.',
	},
	{
		title: 'What are the delivery terms you offer?',
		text:
			'We offer several international delivery terms based on Incoterms, including FOB (Freight on Board), CNF (Cost and Freight), and CIF (Cost, Insurance, and Freight). These terms determine the responsibilities and costs for both the buyer and seller throughout the shipping process.',
	},
]
const AccordionBlog = () => {
	const [activeDefault, setActiveDefault] = useState(0)
	return (
		<Accordion className="accordion dlab-accordion faq-1 box-sort-in m-b30" defaultActiveKey="-1">
			{defaultAccordion.map((d, i) => (
				<div className="panel">
					<div className="acod-head">
						<h6 className="acod-title">
							<Accordion.Toggle as={Link} variant="link"
								className={` ${activeDefault === i ? '' : 'collapsed'}`}
								onClick={() =>
									setActiveDefault(activeDefault === i ? -1 : i)
								} eventKey={`${i}`}>
								{i + 1}. {d.title}
							</Accordion.Toggle>
						</h6>
					</div>

					<Accordion.Collapse eventKey={`${i}`} className="acod-body">
						<div className="acod-content">
							{d.text}
						</div>
					</Accordion.Collapse>
				</div>
			))}
		</Accordion>
	)
}
export { defaultAccordion };
export default AccordionBlog;